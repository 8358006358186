<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-text class="mb-2">
          Регистрация завершена. Проверьте свою электронную почту и перейдите по ссылке для подтверждения.
        </b-card-text>

        <div class="d-flex justify-content-center mt-2">
          <b-link :to="{name: 'login'}">
            <span>Войти</span>
          </b-link>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BLink} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {closeMainLoader} from '@/assets/scripts/scripts'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    VuexyLogo,
    BCardText,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      // status: false,
      // validation rules
      required,
      email,
      siteKey: '',
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.siteKey = process.env.VUE_APP_RECAPTCHA_TOKEN
    this.closeMainLoader()
  },
  methods: {
    closeMainLoader,
    login() {
      axios
        // eslint-disable-next-line no-undef
        .post(process.env.VUE_APP_API_URL + 'auth/login/', {
          remember: true,
          username: this.userEmail,
          password: this.password,
        })
        .then(response => {
          localStorage.setItem('access_token', response.data.access)
          this.route()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Attention!',
              icon: 'AlertTriangleIcon',
              text: error.toString(),
              variant: 'danger',
            },
          })
        })
    },
    route() {
      axios
        // eslint-disable-next-line no-undef
        .get(process.env.VUE_APP_API_URL + 'users/profile/', {
          headers: {Authorization: 'Bearer ' + localStorage.getItem('access_token')},
        })
        .then(response => {
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('username', response.data.username)
          localStorage.setItem('user_id', response.data['pk'])
          if (response.data.role !== 'author') {
            this.$router.push('/author/home')
          } else {
            this.$router.push('/author/home')
          }
          this.$store.commit('app/SET_USER', response.data['pk'])
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Attention!',
              icon: 'AlertTriangleIcon',
              text: error.toString(),
              variant: 'danger',
            },
          })
        })
    },
    // useAuthProvider(provider, proData) {
    //   const ProData = proData || Providers[provider]
    //   this.$Oauth
    //     .authenticate(provider, ProData)
    //     .then(response => {
    //       const rsp = response
    //       if (rsp.code) {
    //         this.responseData.code = rsp.code
    //         this.responseData.provider = provider
    //         this.useSocialLogin()
    //       }
    //     })
    //     .catch(() => {})
    // },
    // useSocialLogin() {
    //   // otp from input Otp form
    //   // hash user data in your backend with Cache or save to database
    //   const pdata = {code: this.responseData.code, otp: this.data.tok, hash: this.hash}
    //   this.$axios
    //     .post(process.env['VUE_APP_API_URL'] + '/social-login/' + this.responseData.provider, pdata)
    //     .then(async response => {
    //       // `response` data base on your backend config
    //       if (response.data.status === 444) {
    //         this.hash = response.data.hash
    //         this.fauth = true // Option show Otp form incase you using 2fa or any addition security apply to your app you can handle all that from here
    //       } else if (response.data.status === 445) {
    //         //do something Optional
    //       } else {
    //         await this.useLoginFirst(response.data.u)
    //       }
    //     })
    //     .catch(() => {
    //       // console.log(err)
    //     })
    // },
    // useLoginFirst(e) {
    //   // this sample of to pust user data to my store
    //   console.log('userdata', e)
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
